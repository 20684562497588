import React, {useState} from "react"
import { Link } from "gatsby"
import axios from "axios"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import spins from "content/images/card-spins.svg"

const StyledCard = styled.div`
  background:  url(${spins}), ${props => props.background};
  background-size: cover;
  background-position: 0% 67%;
  width: 100%;
  min-height: 19rem;
  height: 21rem;
  max-width: 36.56rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .section-label {
    color: rgba(255, 255, 255, 0.47);
  }
  .text {
    color: white;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  padding: 2.56rem 2.31rem;
`

const StyledButton = styled(Link)`
  width: 100%;
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: ${props => props.button};
  border: none;
  font-size: 1rem;
`

const StyledControl = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
  &:hover {
    opacity: 0.9;
  }
`

const CardNewsLetter = ({ background, button }) => {
  const [email, setEmail] = useState("")
  const [submited, setSubmited] = useState(false)
  const postNewsLetter = e => {
    e.preventDefault()
    const url = 'https://api.hsforms.com/submissions/v3/integration/submit/2248861/9327376a-cb40-45af-af14-9d89d203800c'
    var data = {
      "submittedAt": Date.now(),
      "fields": [
        {
          "name": "email",
          "value": email
        }
      ],
      "context": {
        // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        "pageUri": "https://labs.hrflow.ai",
        "pageName": "HrFlow.ai HR ai demos"
      },
      "legalConsentOptions":{ // Include this object when GDPR options are enabled
        "consent":{
          "consentToProcess":true,
          "text":"I agree to allow Example Company to store and process my personal data.",
          "communications":[
            {
              "value":true,
              "subscriptionTypeId":999,
              "text":"I agree to receive marketing communications from Example Company."
            }
          ]
        }
      }
    }
    axios.post(url, data).then(res => {
      setSubmited(true)
    })
  }

  if (submited) {
    return (
      <StyledCard
        background={background}
      >
        <div>
          <div className="section-label">Newsletter</div>
          <div className="text">Thank you for subscribing to our newsletter</div>
        </div>
      </StyledCard>
    )
  }
  return (
    <StyledCard
      background={background}
      onSubmit={e => {
        e.preventDefault();
        postNewsLetter(e)
      }}
    >
      <div>
        <div className="section-label">HrFlow.ai Slack Community</div>
        <div className="text">Join our community of 1000+ HrTech Founders & Developers</div>
      </div>
      <StyledControl>
        <StyledButton to="https://communityinviter.com/apps/hrarchitect/join" target="_blank" button={button} type="submit">
          <span></span><span>Accept invitation </span><FontAwesomeIcon className="icon-right" icon={faArrowRight} />
        </StyledButton>
      </StyledControl>
    </StyledCard>
  )
}


export default CardNewsLetter